<template>
  <core-sidebar
    sidebar-class="w-95 w-xl-75 w-xxl-50"
    no-close-on-route-change
    lazy backdrop shadow
    id="jobs-sidebar"
    title=""
    right
    @shown="handleShowSidebar"
    @hidden="hideMessageListener"
  >
    <template #header="{ hide }">
      <b-button variant="link" v-b-tooltip.hover.bottom :title="translations[10908]" @click="hide">
        <b-icon icon="arrow-right" />
      </b-button>
    </template>
    <b-tabs class="h-90" nav-class="mb-0 bg-aba-secondary" no-nav-style content-class="h-100"
            v-model="active">
      <b-tab lazy active title-link-class="text-white" class="h-100" @click="reloadJobIframe">
        <template #title>
          <b-icon icon="info-circle-fill" scale="0.8" />
          <span class="d-none d-md-inline ml-1">{{ translations[728] }}</span>
        </template>
        <b-overlay :show="iframeLoad" rounded="sm" class="h-100">
          <div class="bg-light h-100 d-flex flex-column">
            <div class="flex-grow-1">
              <template v-if="urlIframe">
                <iframe
                  title="job-iframe"
                  :src="urlIframe"
                  width="100%"
                  height="100%"
                  @load="iframeLoad = false"
                  frameborder="0"
                  id="job-iframe"
                  ref="job-iframe"
                  allowfullscreen
                ></iframe>
              </template>
              <template v-else>
                <iframe
                  title="job-iframe"
                  :src="
                    `${url}/ang/showangex.php?token=${accessTokenApplicant ||
                      accessTokenConsultant ||
                      accessTokenCompany}&sid=${languageSid}&jobnews=1&idang=${jobId}&portal=1&share=${shareJobLink}&cookie=${Number(isCookieFunctionality)}`
                  "
                  width="100%"
                  height="100%"
                  @load="iframeLoad = false"
                  frameborder="0"
                  id="job-iframe"
                  ref="job-iframe"
                  allowfullscreen
                ></iframe>
              </template>
            </div>
          </div>
        </b-overlay>
      </b-tab>
      <b-tab lazy title-link-class="text-white">
        <template #title>
          <b-icon icon="building" scale="0.8" />
          <span class="d-none d-md-inline ml-1">{{ jobCompany }}</span>
        </template>
        <div class="component-widget p-3">
          <company-info />
        </div>
      </b-tab>
      <b-tab lazy title-link-class="text-white">
        <template #title>
          <b-icon icon="list-task" scale="0.8" />
          <span class="d-none d-md-inline ml-1">{{ translations[13995] }}</span>
        </template>
        <div class="component-widget p-3">
          <job-company-component :company="jobCompany" @activeTab="activeTab" />
        </div>
      </b-tab>
    </b-tabs>
  </core-sidebar>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { ProfessionModule } from '@/store/modules/profession';
import CompanyInfo from '@/components/Company/CompanyInfo.vue';
import { ClustersModule } from '@/store/modules/clusters';
import JobCompanyComponent from '@/components/Company/JobCompanyComponent.vue';
import { NotebookModule } from '@/store/modules/notebook';
import { logEvent } from '@/api/EventLogger';
import { EVENT_JOB_CLICK } from '@/config/constant';

const URL = {
  DEFAULT_ANALYSE_PRODUCTION_URL: '/jobnet',
  DEFAULT_ANALYSE_DEVELOPMENT_URL: 'https://test14.job-server.net/jobnet',
};

@Component({
  components: {
    CompanyInfo,
    JobCompanyComponent,
  },
})
export default class JobsSidebar extends Vue {
  iframeLoad = true;

  active = 0;

  get isCookieFunctionality() {
    return AppModule.isCookieFunctionality;
  }

  @Watch('active')
  handler(value: number) {
    if (value === 0) {
      this.logJobView();
    }
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  get translations() {
    return AppModule.translation;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get jobId() {
    return ProfessionModule.jobId;
  }

  get urlIframe() {
    return ClustersModule.urlIframe;
  }

  get jobCompany() {
    return ProfessionModule.jobFirm;
  }

  get shortView() {
    return ProfessionModule.shortView.length ? '&no_header_sv=1' : '';
  }

  get url() {
    return process.env.NODE_ENV === 'production'
      ? URL.DEFAULT_ANALYSE_PRODUCTION_URL
      : URL.DEFAULT_ANALYSE_DEVELOPMENT_URL;
  }

  get shareJobLink() {
    return window.btoa(encodeURI(`${window.location.origin}/jobs?lang=${this.$route.query.lang}&jobViewId=${this.jobId}&jobFirmName=${this.jobCompany}&jobShortview=${ProfessionModule.shortView}`));
  }

  // TokenApplicant
  get accessTokenApplicant() {
    return UserModule.accessTokenApplicant;
  }

  // TokenConsultant
  get accessTokenConsultant() {
    return UserModule.accessTokenConsultant;
  }

  // TokenCompany
  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  activeTab(tab: number) {
    this.active = tab;
  }

  async handleMessage(event: MessageEvent) {
    switch (event.data.message) {
      case 'login_required':
        this.$bvModal.show('not-auth-modal');
        break;
      case 'note_job':
        await this.handleToggleNotepad(event.data);
        break;
      default:
        break;
    }
  }

  async handleToggleNotepad(data: {id: number, saved: boolean}) {
    if (this.accessTokenConsultant || this.accessTokenApplicant) {
      await NotebookModule.toogleNotepadItem({ type: 'job', item_id: data.id, isMarked: data.saved });
    }
  }

  async logJobView() {
    if (!this.jobId) return;
    await logEvent({
      event_id: EVENT_JOB_CLICK,
      object_id: this.jobId,
    });
  }

  reloadJobIframe() {
    if (this.active !== 0) return;
    const jobFrame = document.getElementById('job-iframe') as HTMLIFrameElement;
    if (jobFrame) {
      jobFrame.src += '';
    }
  }

  addMessageListener() {
    window.addEventListener('message', this.handleMessage, false);
  }

  hideMessageListener() {
    window.removeEventListener('message', this.handleMessage, false);
  }

  handleShowSidebar() {
    this.addMessageListener();
    this.logJobView();
  }
}
</script>
