import { Component, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';
import { AppModule } from '@/store/modules/app';

@Component({
  name: 'googleTranslate',
})
export default class extends Vue {
  translateObject = '';

  translateMap: { [key: string]: string } = {};

  get isCookieFunctionality() {
    return AppModule.isCookieFunctionality;
  }

  get lang() {
    return AppModule.language;
  }

  get enabledTranslate() {
    return AppModule.setting?.translation_of_positions;
  }

  cancel: any = null;

  get isLang() {
    return this.lang === 'de';
  }

  async result(text: string, useKey?: string) {
    if (!this.isCookieFunctionality) {
      return;
    }
    try {
      const { CancelToken } = axios;
      await axios
        .post('https://www.googleapis.com/language/translate/v2/', null, {
          params: {
            key: 'AIzaSyDp3RP8jukZDZbc37MSA6npdmZU8Cd-tJM',
            q: text.replace('&nbsp;', ' '),
            target: this.lang,
            format: 'text',
          },
          cancelToken: new CancelToken((c) => {
            this.cancel = c;
          }),
        })
        .then((response) => {
          if (!useKey) {
            this.translateObject = response.data.data.translations[0].translatedText;
          } else {
            Vue.set(this.translateMap, useKey, response.data.data.translations[0].translatedText);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  async translate(text: string, useKey?: string) {
    if (this.isLang) {
      return;
    }
    if (this.enabledTranslate) {
      return;
    }
    await this.result(text, useKey);
  }

  async allTranslate(text: string, useKey?: string) {
    if (this.isLang) {
      return;
    }
    await this.result(text, useKey);
  }

  async allTranslateIgnoreLang(text: string, useKey?: string, isTranslate = true) {
    if (isTranslate) {
      await this.result(text, useKey);
    }
  }
}
