<template>
  <div>
    <b-card-body class="px-0 pt-1 pb-0">
      <b-row no-gutters class="g-1">
        <template v-for="(job, index) in professionsToShow" >
          <b-col cols="12" lg="6" :key="index">
            <professions-item
              :top="index < 6"
              :index="index + 1"
              :title="job.tit"
              :inf="job.inf"
              :pdf="job.pdf"
              :plt="job.plt"
              :quantity="job.amt"
              :id="job.id"
              :is-auth="isAuth"
              :language-sid="languageSid"
              :regulared-profession="job.regulated_profession"
            />
          </b-col>
        </template>
      </b-row>
    </b-card-body>
    <b-card-body v-if="isShowMoreVisible" class="p-0">
      <a href="#" @click.prevent="jobShow = !jobShow" class="togglelabel collapsed fs-6 fw-bold">
        <span class="label">{{ jobShow ? translations[14014] : translations[13645] }}</span>
      </a>
    </b-card-body>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import ProfessionsItem from '@/components/Home/ProfessionsItem.vue';
import { AppModule } from '@/store/modules/app';

const DEFAULT_COUNT_TO_SHOW = 50;

@Component({
  components: {
    ProfessionsItem,
  },
})
export default class ProfessionsList extends Vue {
  @Prop({ default: () => [] }) private items!: any;

  @Prop({ default: false }) isAuth!: boolean;

  @Prop({ default: '' }) languageSid!: string;

  get translations() {
    return AppModule.translation;
  }

  get isShowMoreVisible() {
    return this.items.length > DEFAULT_COUNT_TO_SHOW;
  }

  jobShow = false;

  get professionsToShow() {
    return this.jobShow ? this.items : this.items.slice(0, DEFAULT_COUNT_TO_SHOW);
  }
}
</script>
<style scoped lang="scss"></style>
