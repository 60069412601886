import HTTP from '@/plugins/axios';
import { ILoginRequest, ISignin, IRegister } from '@/types/Login';
import axios from 'axios';

export const loginApplicant = async(data: ISignin) => {
  const response = await HTTP.post('/login', data);
  return response.data as ILoginRequest;
};

export const loginConsultant = async(data: ISignin) => {
  const response = await HTTP.post('/consultant/login', data);
  return response.data;
};

export const loginCompany = async(data: ISignin) => {
  const response = await HTTP.post('/company/login', data);
  return response.data;
};

export const loginRefresh = async(refresh: string) => {
  const response = await HTTP.put('/refresh_token', { token: refresh });
  return response.data as ILoginRequest;
};

export const refreshSession = async() => {
  const response = await axios.get('/jobnet/web/logout.py');
  return response.data;
};

export const getUserInfoApplicant = async() => {
  const response = await HTTP.get('/user/info');
  return response.data;
};

export const getUserInfoConsultant = async() => {
  const response = await HTTP.get('/consultant/info');
  return response.data;
};

export const getUserInfoCompany = async() => {
  const response = await HTTP.get('/company/info');
  return response.data;
};

export const passwordRecovery = async(data: any) => {
  const response = await HTTP.post('/user/password_recovery', data);
  return response.data;
};

export const passwordRecoveryConsultant = async(data: any) => {
  const response = await HTTP.post('/consultant/password_recovery', data);
  return response.data;
};

export const passwordRecoveryCompany = async(data: any) => {
  const response = await HTTP.post('/company/password_recovery', data);
  return response.data;
};

export const registerApplicant = async(data: IRegister) => {
  const response = await HTTP.post('/user/jobnews_registration_request', data);
  return response.data;
};

export const registerCompany = async(data: IRegister) => {
  const response = await HTTP.post('/company/registration_request', data);
  return response.data;
};
export const getGenerateToken = async() => {
  const response = await HTTP.get('/generate_sut');
  return response.data;
};

export const getConsultantMenu = async() => {
  const response = await HTTP.get('/consultant/main_menu');
  return response.data;
};
export const getCompanyMenu = async() => {
  const response = await HTTP.get('/company/main_menu');
  return response.data;
};
export const getTempToken = async() => {
  const response = await HTTP.get('/generate_sut');
  return response.data;
};
export const getCaptchaVerify = async(token: string) => {
  const response = await HTTP.post('/cloudflare/verify', {
    token,
  }, {
    // headers: {
    //   'CF-Connecting-IP': ip,
    // },
  });
  return response.data;
};
export const checkRegistrationEvent = async(params: {sid: string, name: string, user_type: number}) => {
  const response = await HTTP.get('/registration_event/check', {
    params,
  });
  return response.data;
};
