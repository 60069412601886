<template>
  <b-modal id="modal-regulated-professions" :title="translations[15304]" body-class="fs-6" hide-footer centered size="md">
    <template #default>
      <p class="mb-2">
        {{ translations[15302] }}
      </p>
      <p class="link-wrapper"><b-icon icon="link45deg" class="mr-1" /><span v-html="translations[15305]"></span></p>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';

@Component
export default class RegulatedProfessionsModal extends Vue {
  get translations() {
    return AppModule.translation;
  }
}
</script>

<style lang="scss" scoped>
.link-wrapper {
  ::v-deep a {
    color: var(--aba-primary);

    &:hover {
      color: rgb(var(--aba-primary-800)) !important;
    }
  }
}
</style>
