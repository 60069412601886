<template>
  <div class="job-component">
    <b-row align-v="end" class="mb-4 g-1" no-gutters>
      <b-col cols="12">
        <h4 v-html="jobsTitle"></h4>
        <div class="mb-3" v-if="isJobSearch">
          <filter-tag
            v-for="(item, index) in selectProfessionTags"
            :key="item.id"
            :count="item.count"
            :title="item.title"
            @delete="removeProfessionTag(index)"
          />

          <filter-tag v-if="selectLocationTags" :title="selectLocationTags.name" @delete="removeLocationTag()" />

          <filter-tag v-for="item in selectedFederalStateTags" :key="item.id" :title="item.location_name" @delete="removeStateTag(item.id)" />

          <filter-tag v-if="selectTags.textTag" :title="selectTags.textTag" @delete="removeTextTag()" />

          <filter-tag v-if="selectTags.fullTimeTag && selectSwitchFilter" :title="translations[13620]" @delete="removeFullTimeTags()" />

          <filter-tag v-if="selectTags.partTimeTag && selectSwitchFilter" :title="translations[13621]" @delete="removePartTimeTags()" />

          <filter-tag v-if="selectTags.practiceTag && selectSwitchFilter" :title="translations[13622]" @delete="removePracticeTags()" />

          <filter-tag v-if="selectTags.skillLevel2Tag && selectSwitchSkillFilter" :title="translations[13625]" @delete="removeSkillLevel2Tags()" />

          <filter-tag v-if="selectTags.skillLevel3Tag && selectSwitchSkillFilter" :title="translations[13626]" @delete="removeSkillLevel3Tags()" />

          <filter-tag v-if="selectTags.clusterTag" :title="clustersName(selectTags.clusterTag)" @delete="removeClusterTags()" />
        </div>
      </b-col>
      <b-col cols="auto">
        <b-button @click="toProfessions()" variant="primary" size="sm" class="position-relative z-3" v-b-tooltip.hover :title="translations[13756]">
          <b-icon icon="chevron-left"></b-icon>
          <span class="ml-1 d-none d-md-inline-block">{{ translations[2901] }}</span>
        </b-button>
      </b-col>
      <b-col cols="auto">
        <!--        v-if="$route.name === 'Jobs'"-->
        <b-button size="sm" variant="primary" class="position-relative z-3" v-b-toggle.jobs-filter>
          <span v-b-tooltip.hover :title="translations[13590]">
            <b-icon icon="sliders"></b-icon>
            <span class="ml-1 d-none d-md-inline-block">{{ translations[13590] }}</span>
          </span>
        </b-button>
        <!--        <b-button v-if="$route.name === 'Map'" size="sm" variant="primary" class="position-relative z-3" v-b-toggle.map-filter>-->
        <!--          <span v-b-tooltip.hover :title="translations[13590]">-->
        <!--            <b-icon icon="sliders"></b-icon>-->
        <!--            <span class="ml-1 d-none d-md-inline-block">{{ translations[13590] }}</span>-->
        <!--          </span>-->
        <!--        </b-button>-->
      </b-col>
      <b-col v-if="isSortingVisible" cols="auto">
        <b-select size="sm" v-model="dateSort" @change="jobsLoading">
          <b-select-option :value="0">{{ translations[11486] }}</b-select-option>
          <b-select-option :value="4">{{ translations[11487] }}</b-select-option>
        </b-select>
      </b-col>
      <b-col cols="auto" class="ml-auto" v-if="!isJobMap">
        <a
          class="btn position-relative z-3 btn-primary btn-sm"
          :href="`mailto:?subject=${translations[13893]}&body=${translations[13890]} ${urlParams}`"
        >
          <b-icon icon="envelope"></b-icon>
          <span class="ml-1 d-none d-md-inline-block">
            {{ translations[10473] }}
          </span>
        </a>
        <!--        <b-button variant="primary" size="sm" class="position-relative z-3">-->
        <!--          <b-icon icon="envelope"></b-icon>-->
        <!--          <span class="ml-1 d-none d-md-inline-block">-->
        <!--            {{ translations[10473] }}-->
        <!--          </span>-->
        <!--&lt;!&ndash;          <b-icon v-if="!isAuth" icon="shield-lock" class="ml-1"></b-icon>&ndash;&gt;-->
        <!--        </b-button>-->
      </b-col>
    </b-row>
    <b-row class="mb-3" no-gutters>
      <b-col></b-col>
      <b-col cols="auto" class="d-flex align-items-center">
        <template v-if="jobs?.length">
          <b-dropdown variant="primary" size="sm" class="rounded-0 rounded-start mr-1">
            <template v-slot:button-content>
              {{ paginationPerPage }}
            </template>
            <b-dropdown-item @click="[(paginationPerPage = 20), jobsLoading(), changePagination()]">20</b-dropdown-item>
            <b-dropdown-item @click="[(paginationPerPage = 50), jobsLoading(), changePagination()]">50</b-dropdown-item>
            <b-dropdown-item @click="[(paginationPerPage = 100), jobsLoading(), changePagination()]">100</b-dropdown-item>
          </b-dropdown>
          <b-pagination
            class="mb-0"
            size="sm"
            v-model="paginationPage"
            :total-rows="count"
            :per-page="paginationPerPage"
            @change="[jobsLoading(), changePagination()]"
          />
        </template>
      </b-col>
    </b-row>
    <b-overlay :show="loading" rounded="sm" variant="primary" :opacity="0.2" style="min-height: 200px">
      <template v-if="jobs?.length || loading">
        <job-item
          v-for="(item, index) in jobs"
          :key="index"
          :count="countNumber(index + 1)"
          :date="item[0]"
          :id="item[1]"
          :title="item[2]"
          :description="item[3]"
          :firm-name="item[4]"
          :letter="item[5]"
          :shortview="item[7]"
          :contact="item[8]"
          :update="item[9]"
          :translate-job="item[10]"
          :regulated-profession="item[10] === 'regulated_profession'"
          :is-auth="isAuth"
          :active="isNotebookItem(item[1]).length"
          :is-jobs-sidebar="true"
        />
      </template>
      <h5 v-else class="text-center">{{ translations[13786] }}</h5>
    </b-overlay>
    <b-row class="mt-3" no-gutters>
      <b-col></b-col>
      <b-col cols="auto" class="d-flex align-items-center">
        <template v-if="jobs?.length">
          <b-dropdown variant="primary" size="sm" class="rounded-0 rounded-start mr-1">
            <template v-slot:button-content>
              {{ paginationPerPage }}
            </template>
            <b-dropdown-item @click="[(paginationPerPage = 20), jobsLoading(), changePagination()]">20</b-dropdown-item>
            <b-dropdown-item @click="[(paginationPerPage = 50), jobsLoading(), changePagination()]">50</b-dropdown-item>
            <b-dropdown-item @click="[(paginationPerPage = 100), jobsLoading(), changePagination()]">100</b-dropdown-item>
          </b-dropdown>
          <b-pagination
            class="mb-0"
            size="sm"
            v-model="paginationPage"
            :total-rows="count"
            :per-page="paginationPerPage"
            @change="[jobsLoading(), changePagination()]"
          />
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { ProfessionModule } from '@/store/modules/profession';
import JobItem from '@/components/Home/JobItem.vue';
import { JobType } from '@/types/ProfessionType';
// eslint-disable-next-line import/named
import { NotebookModule } from '@/store/modules/notebook';
import { MapModule } from '@/store/modules/map';
import LocaleStringMixin from '@/mixins/LocaleStringMixin';
import { scrollToTop } from '@/helpers/interactivityHelpers';
import { mixins } from 'vue-class-component';
import FilterTag from '@/components/FilterTag.vue';

@Component({
  components: {
    JobItem,
    FilterTag,
  },
})
export default class JobComponent extends mixins(LocaleStringMixin) {
  get urlFilterParams() {
    return ProfessionModule.urlFilter;
  }

  get urlParams() {
    return String(window.location.origin + this.$route.fullPath).replace('&', '%26');
  }

  get translations() {
    return AppModule.translation;
  }

  loading = false;

  get jobsTitle() {
    if (this.jobType === JobType.search || this.jobType === JobType.map) {
      if (this.selectProfession || this.selectLocationTags) {
        return this.count === 1 ? this.translations[14832] : this.translations[13850]?.replace('%s', String(this.count));
      }
      return `${this.toLocaleLangString(this.count || 0)} ${this.count === 1 ? this.translations[13785] : this.translations[13774]}`;
    }
    return `${this.toLocaleLangString(this.count || 0)} ${this.count === 1 ? this.translations[13772] : this.translations[13773]} ${
      this.professionTitle
    }`;
  }

  get jobs() {
    return ProfessionModule.jobs.jobs;
  }

  get jobType() {
    return ProfessionModule.jobType;
  }

  get clusters(): any[] {
    return AppModule.settingPortal.clusters;
  }

  get isJobSearch() {
    return this.jobType === JobType.search;
  }

  get isJobMap() {
    return this.jobType === JobType.map;
  }

  get count() {
    return ProfessionModule.jobs.amt || 0;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  // search professions
  get selectProfession() {
    if (ProfessionModule.searchJobProfessionTitle) {
      return {
        name: ProfessionModule.searchJobProfessionTitle?.map((v: any) => v.title).join(', ') || '',
      };
    }
    return ProfessionModule.searchJobProfessionTitle;
  }

  get selectProfessionTags() {
    return ProfessionModule.searchJobProfessionTitle;
  }

  get selectLocationTags() {
    return ProfessionModule.searchJobLocationTitle;
  }

  get selectedFederalStateTags() {
    return ProfessionModule.selectedFederalStateItems;
  }

  get selectTags() {
    return ProfessionModule.searchTags;
  }

  get selectSwitchFilter() {
    return !(this.selectTags.fullTimeTag && this.selectTags.partTimeTag && this.selectTags.practiceTag);
  }

  get selectSwitchSkillFilter() {
    return !(this.selectTags.skillLevel2Tag && this.selectTags.skillLevel3Tag);
  }

  get professionTitle() {
    return ProfessionModule.professionTitle;
  }

  get jobsMapIds() {
    return MapModule.selectedRegionJobsIds;
  }

  // pagination
  get paginationPage(): number {
    return ProfessionModule.jobPagination.page;
  }

  set paginationPage(val) {
    ProfessionModule.SetPagination({ obj: 'page', value: val });
  }

  get paginationPerPage(): number {
    return ProfessionModule.jobPagination.per_page;
  }

  set paginationPerPage(val) {
    ProfessionModule.SetPagination({ obj: 'per_page', value: val });
  }

  get notebookJobs() {
    return NotebookModule.notebookJobs;
  }

  // END pagination
  // Sort
  get isSortingVisible() {
    return this.jobType === JobType.search && this.selectProfession?.name && this.selectLocationTags?.name;
  }

  get dateSort(): string {
    return ProfessionModule.jobSort.sort;
  }

  set dateSort(val) {
    ProfessionModule.SetSort({ obj: 'sort', value: val });
  }
  // END Sort

  clustersName(id: number) {
    return this.clusters?.find((x: any) => x.id === id)?.name;
  }

  countNumber(count: number) {
    return (this.paginationPage - 1) * this.paginationPerPage + count;
  }

  isNotebookItem(value: number) {
    return this.notebookJobs.filter((x: { id: number }) => x.id === value);
  }

  async loadJobListItems() {
    this.loading = true;
    await ProfessionModule.GetJobs({
      sid: this.languageSid,
    });
    this.loading = false;
  }

  async loadJobListItemsSearch() {
    this.loading = true;
    await ProfessionModule.GetJobsSearch({
      sid: this.languageSid,
    });
    this.loading = false;
  }

  async loadJobListItemsMap() {
    this.loading = true;
    await ProfessionModule.GetJobsMap({
      params: { sid: this.languageSid },
      ids: this.jobsMapIds,
    });
    this.loading = false;
  }

  setUrlParams() {
    const currentQueryParams = { ...this.$route.query };
    currentQueryParams.filters = this.urlFilterParams;
    this.$router.replace({ query: currentQueryParams }).catch(() => {});
  }

  async jobsLoading() {
    this.$nextTick(async() => {
      if (this.jobType === JobType.search) {
        await this.loadJobListItemsSearch();
      } else if (this.jobType === JobType.map) {
        await this.loadJobListItemsMap();
      } else if (this.jobType === JobType.items) {
        await this.loadJobListItems();
      }
      setTimeout(() => {
        scrollToTop();
      }, 400);
    });
  }

  changePagination() {
    this.$nextTick(() => {
      this.setUrlParams();
    });
  }

  toProfessions() {
    ProfessionModule.SetParamsDefault();
    ProfessionModule.SetTagsDefault();
    this.$router.replace({ query: { filters: undefined } }).catch(() => {});

    if (this.jobType === JobType.map) {
      AppModule.SetVisibleMap(true);
      AppModule.SetVisibleJobs(false);
    } else {
      AppModule.SetVisibleJobs(false);
    }
  }

  uploadComponent() {
    ProfessionModule.SET_PAGINATION_DEFAULT();
    this.setUrlParams();
    AppModule.SetVisibleJobs(false);
    this.$nextTick(() => {
      AppModule.SetVisibleJobs(true);
    });
  }

  isRemoveAllEmployment() {
    this.$nextTick(() => {
      if (!this.selectTags.fullTimeTag && !this.selectTags.partTimeTag && !this.selectTags.practiceTag) {
        ProfessionModule.SetFilter({ obj: 'fulltime', value: 0 });
        ProfessionModule.SetSearchTags({ tag: 'fullTimeTag', value: 0 });
        ProfessionModule.SetFilter({ obj: 'parttime', value: 0 });
        ProfessionModule.SetSearchTags({ tag: 'partTimeTag', value: 0 });
        ProfessionModule.SetFilter({ obj: 'praktikum', value: 0 });
        ProfessionModule.SetSearchTags({ tag: 'practiceTag', value: 0 });
      }
      this.uploadComponent();
    });
  }

  isRemoveAllLevel() {
    this.$nextTick(() => {
      if (!this.selectTags.skillLevel2Tag && !this.selectTags.skillLevel3Tag) {
        ProfessionModule.SetFilter({ obj: 'skill_level2', value: 0 });
        ProfessionModule.SetSearchTags({ tag: 'skillLevel2Tag', value: 0 });
        ProfessionModule.SetFilter({ obj: 'skill_level3', value: 0 });
        ProfessionModule.SetSearchTags({ tag: 'skillLevel3Tag', value: 0 });
      }
      this.uploadComponent();
    });
  }

  removeProfessionTag(index: number) {
    this.selectProfessionTags.splice(index, 1);
    ProfessionModule.SetSearchJobProfession(this.selectProfessionTags);
    ProfessionModule.SetSearchJobProfessionTitle(this.selectProfessionTags);
    ProfessionModule.SetSearch({ obj: 'profession_id', value: this.selectProfessionTags?.map((v: any) => v.id).join(',') || null });
    this.uploadComponent();
  }

  removeLocationTag() {
    ProfessionModule.SetSearchJobLocation(null);
    ProfessionModule.SetSearchJobLocationTitle(null);
    ProfessionModule.SetSearch({ obj: 'location_id', value: null });
    this.uploadComponent();
  }

  removeStateTag(id: string) {
    const resultArray = this.selectedFederalStateTags.filter((el) => el.id !== id);
    ProfessionModule.SetSelectedFederalStates(resultArray);
    this.uploadComponent();
  }

  removeTextTag() {
    ProfessionModule.SetSearchTags({ tag: 'textTag', value: null });
    ProfessionModule.SetSearch({ obj: 'q', value: '' });
    this.uploadComponent();
  }

  removeFullTimeTags() {
    ProfessionModule.SetFilter({ obj: 'fulltime', value: 1 });
    ProfessionModule.SetSearchTags({ tag: 'fullTimeTag', value: 1 });
    this.isRemoveAllEmployment();
  }

  removePartTimeTags() {
    ProfessionModule.SetFilter({ obj: 'parttime', value: 1 });
    ProfessionModule.SetSearchTags({ tag: 'partTimeTag', value: 1 });
    this.isRemoveAllEmployment();
  }

  removePracticeTags() {
    ProfessionModule.SetFilter({ obj: 'praktikum', value: 1 });
    ProfessionModule.SetSearchTags({ tag: 'practiceTag', value: 1 });
    this.isRemoveAllEmployment();
  }

  removeSkillLevel2Tags() {
    ProfessionModule.SetFilter({ obj: 'skill_level2', value: 1 });
    ProfessionModule.SetSearchTags({ tag: 'skillLevel2Tag', value: 1 });
    this.isRemoveAllLevel();
  }

  removeSkillLevel3Tags() {
    ProfessionModule.SetFilter({ obj: 'skill_level3', value: 1 });
    ProfessionModule.SetSearchTags({ tag: 'skillLevel3Tag', value: 1 });
    this.isRemoveAllLevel();
  }

  removeClusterTags() {
    ProfessionModule.SetFilter({ obj: 'cluster_id', value: 0 });
    ProfessionModule.SetSearchTags({ tag: 'clusterTag', value: null });
    this.uploadComponent();
  }

  created() {
    ProfessionModule.SetSort({ obj: 'sort', value: 0 });
    this.jobsLoading();
  }
}
</script>
<style scoped lang="scss"></style>
